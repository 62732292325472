.maina-interactiv__title {
  color: $main-color;
  @include playfair;
  font-size: 36px;
  line-height: 42px;
  max-width: 580px;
}

.maina-interactiv__list {
  margin-top: -80px;
  @media (max-width: $screen-md) {
    margin-top: 20px;
  }
}

.maina-interactiv__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $screen-md) {
    display: block;
  }
}

.maina-interactiv__info {
  width: 100%;
  max-width: 580px;
  .church-inner__descr {
    margin-left: 45px;
  }
  .church-inner__descr-text {
    max-width: 220px;
  }
  @media (max-width: $screen-md) {
    max-width: 100%;
    margin-bottom: 30px;
  }
  @media (max-width: $screen-sm) {
    text-align: center;
  }
}

.maina-interactiv__img {
  @media (max-width: $screen-md) {
    max-width: 30%;
    margin: 0 auto;
  }
  @media (max-width: $screen-sm) {
    max-width: 70%;
  }
}

.maina-interactiv__dots {
  display: flex;
  align-items: center;
  margin-top: -80px;
  @media (max-width: $screen-lg) {
    margin-top: 20px;
  }
}

.maina-interactiv__dot {
  position: relative;
  max-width: 75px;
  cursor: pointer;
  &:hover {
    .dot-active {
      opacity: 1;
    }
  }
  .dot-active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    transition: all .2s;
  }
  &+& {
    margin-left: 20px;
  }
  &.active {
    .dot-active {
      opacity: 1;
    }
    .dot-noactive {
      opacity: 0;
    }
  }
}