.content {
  min-height: calc(100vh - 195px);
  padding-top: 140px;
  h1 {
    @include playfair;
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 40px;
  }
  p.strong {
    @include playfair-regular;
    color: $nav-color;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 40px;
  }
  p {
    @include playfair-regular;
    color: $nav-color;
    font-size: 16px;
    line-height: 28px;
  }
  p+p {
    margin-top: 20px;
  }
  @media (max-width: $screen-md) {
    min-height: auto;
    padding-top: 100px;
    h1 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    p.strong {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
    p+p {
      margin-top: 10px;
    }
  }
}