.church {
  position: absolute;
  top: 30%;
  left: 30%;
  right: 0;
  bottom: 0;
  z-index: 2;
  transform-origin: left top;
  transition: all .5s;
  width: 3250px;
  height: 1700px;
  transform: scale(0.615);
  &.active {
    transform: translate(-990px,-200px);
    z-index: 4;
  }
  @media(max-width: 2400px) {
    left: 5%;
    top: 10%;
  }
  @media(max-width: 1800px) {
    left: -8%;
    top: 10%;
  }
  @media(max-width: 1600px) {
    width: 2600px;
    height: 1529px;
    background: url('../img/church/bg.jpg') center no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
  }
  @media(max-width: 1366px) {
    &.active {
      transform: translate(-1190px,-200px);
    }
  }
  @media(max-width: 1256px) {
    &.active {
      transform: translate(-1400px,-200px);
    }
  }
  @media(max-width: $screen-lg) {
    &.active {
      transform: translate(-1700px,-200px);
    }
  }
  @media(max-width: $screen-md) {
    transform: none;
    width: 100%;
    height: auto;
    position: static;
    &.active {
      transform: none;
    }
  }
}

.church__list {
  width: 2272px;
  margin: 0 auto;
  position: relative;
  @media(max-width: $screen-md) {
    width: 100%;
    margin: 0;
    padding: 120px 0;
  }
}

.church__item {
  text-align: center;
  max-width: 485px;
  position: absolute;
  cursor: pointer;
  display: block;
  z-index: 1;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../img/church/hover.png') center no-repeat;
    background-size: contain;
    opacity: 0;
    @extend .trs;
    z-index: -1;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    .church__name {
      color: $link-color;
    }
  }
  &--1 {
    top: 469px;
    right: 0;
  }
  &--2 {
    top: 117px;
    right: 437px;
  }
  &--3 {
    top: 316px;
    right: 843px;
  }
  &--4 {
    top: 560px;
    left: 440px;
  }
  &--5 {
    top: 1003px;
    left: 510px;
  }
  &--6 {
    top: 824px;
    left: 0;
  }
  &--7 {
    top: 285px;
    left: 67;
  }
  @media(max-width: $screen-md) {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    text-align: center;
    &+& {
      margin-top: 30px;
    }
  }
}

.church__img {
  margin-bottom: 30px;
  @media(max-width: $screen-md) {
    max-width: 90%;
    margin: 0 auto 30px;
  }
}

.church__name {
  @extend .trs;
  @include playfair;
  color: $dark-gray-color;
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  display: block;
  max-width: 325px;
  margin: 0 auto 15px;
}

.church__line {
  position: absolute;
  &--1 {
    top: 161px;
    left: calc(100% + 13px);
  }
  &--2 {
    top: 163px;
    right: -200px;
  }
  &--3 {
    bottom: -43px;
    right: -269px;
  }
  &--4 {
    top: -89px;
    left: -70px;
  }
  &--5 {
    top: calc(100% + 20px);
    left: 186px;
  }
  &--6 {
    top: calc(100% + 12px);
    left: 140px;
  }
  @media(max-width: $screen-md) {
    display: none;
  }
}

.church__path {
  use {
    transition: all 5s;
  }
  &.anim {
    use {
      stroke-dasharray: 500 100;
    }
    &.church__path--5 {
      use {
        stroke-dasharray: 700 100;
      }
    }
  }
}