.breadcrumbs__list {
  display: flex;
}

.breadcrumbs__item {
  &:first-child {
    .breadcrumbs__link {
      padding-left: 9px;
      &:before {
        display: none;
      }
    }
  }
}

.breadcrumbs__link {
  @include ubuntu-medium;
  color: #ffffff;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 9px;
  @extend .trs;
  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: #ffffff;
    margin-right: 7px;
  }
  &:hover {
    color: $dark-gray-color;
  }
}