@font-face {
  font-family: 'Playfair';
  src: url('../fonts/PlayfairDisplay-Black.eot');
  src: url('../fonts/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/PlayfairDisplay-Black.woff2') format('woff2'),
      url('../fonts/PlayfairDisplay-Black.woff') format('woff'),
      url('../fonts/PlayfairDisplay-Black.ttf') format('truetype'),
      url('../fonts/PlayfairDisplay-Black.svg#PlayfairDisplay-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Light.eot');
  src: url('../fonts/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Ubuntu-Light.woff2') format('woff2'),
      url('../fonts/Ubuntu-Light.woff') format('woff'),
      url('../fonts/Ubuntu-Light.ttf') format('truetype'),
      url('../fonts/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair';
  src: url('../fonts/PlayfairDisplay-Regular.eot');
  src: url('../fonts/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
      url('../fonts/PlayfairDisplay-Regular.woff') format('woff'),
      url('../fonts/PlayfairDisplay-Regular.ttf') format('truetype'),
      url('../fonts/PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Medium.eot');
  src: url('../fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Ubuntu-Medium.woff2') format('woff2'),
      url('../fonts/Ubuntu-Medium.woff') format('woff'),
      url('../fonts/Ubuntu-Medium.ttf') format('truetype'),
      url('../fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Regular.eot');
  src: url('../fonts/Ubuntu-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Ubuntu-Regular.woff2') format('woff2'),
      url('../fonts/Ubuntu-Regular.woff') format('woff'),
      url('../fonts/Ubuntu-Regular.ttf') format('truetype'),
      url('../fonts/Ubuntu-Regular.svg#Ubuntu-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}
