.church-inner__top {
  position: relative;
  height: 100vh;
  min-height: 750px;
  color: white;
  text-align: center;
  @include flexCenter;
  flex-direction: column;
  @media (max-width: 1300px) {
    max-height: 720px
  }
  @media (max-width: $screen-md) {
    min-height: 540px;
    height: auto;
  }
}
.church-inner__top--hidden{
  .church-inner__breadcrumbs,
  .church-inner__title,
  .church-inner__main-controls,
  .church-inner__more-history {
    opacity: 0;
    visibility: hidden;
  }
}

.church-inner__top-video {
  @include absoluteZero;
  z-index: -2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img,video {
    width: 100%;
  }
  .church-inner__top-bg {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  @media (max-width: 1300px) {
    img,video {
      width: auto;
    }
  }
  @media (max-width: $screen-md) {
    video,img {
      width: auto;
      max-width: none;
      height: 600px;
    }
  }
}

.church-inner__top-video-wrapper {
  position: relative;
	padding-bottom: 100%; /* 16:9 */
	padding-top: 25px;
  height: 0;
  iframe {
    @include absoluteZero;
    width: 100%;
    height: 100%;
  }
}

.church-inner__breadcrumbs {
  margin-bottom: 25px;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.church-inner__title {
  @include playfair;
  font-size: 64px;
  line-height: 64px;
  /*max-width: 700px;*/
  max-width: 950px;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  @media (max-width: $screen-md) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: $screen-sm) {
    font-size: 32px;
    line-height: 38px;
  }
}

.church-inner__main-controls{
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.church-inner__more-history {
  padding-bottom: 30px;
  max-width: 165px;
  margin: 16px auto 0;
  cursor: pointer;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  @extend .trs;
  &:hover {
    /*padding-bottom: 20px;
    .church-inner__more-history-text {
      margin-bottom: 25px;
    }*/
    .church-inner__more-history-icon{
      transform: translateY(20px);
    }
  }
  @media (max-width: $screen-lg) {
    display: none;
  }
}

.church-inner__more-history-text {
  display: block;
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  @extend .trs;
}

.church-inner__more-history-icon {
  transition: transform .2s ease-in-out;
}

.church-inner__show-map {
  margin-bottom: 100px;
}

.church-inner__show-map {
  animation-delay: 0.5s;
}

.church-inner__subinfo {
  animation-delay: 1s;
}
.church-inner__interactiv {
  animation-delay: 1.5s;
  .col + .col {
    margin-left: 20px;
  }
  @media (max-width: $screen-md) {
    .col + .col {
      margin-left: 0px;
    }
  }
}

.church-inner__descr {
  position: relative;
  @media (max-width: $screen-sm) {
    margin-left: 45px;
  }
}

.church-inner__descr-icon {
  position: absolute;
  top: 8px;
  right: calc(100% + 20px);
  width: 22px;
  height: 22px;
  overflow: hidden;
  @media (max-width: 1100px) {
    display: none;
  }
}

.church-inner__descr-text {
  color: rgba($hover-link-color, 0.7);
  font-size: 14px;
  line-height: 24px;
}

.church-inner__bell{
  position: absolute;
  top: calc(56.25vw - 64px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  z-index: 3;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    transition: background .3s ease-in-out;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxwYXRoIGQ9Ik0yNS43NTE1LDYuMzM2ODc1IEMzMS40MTIwMzgxLDExLjk1MDMxMDQgMzEuNDEyMDM4MSwyMS4wNDk2ODk2IDI1Ljc1MTUsMjYuNjYzMTI1IE0yMC42MzMsMTEuNDExMjUgQzIzLjQ2MzI2OTEsMTQuMjE3OTY3NyAyMy40NjMyNjkxLDE4Ljc2NzY1NzMgMjAuNjMzLDIxLjU3NDM3NSIgaWQ9IlNoYXBlIiBzdHJva2U9IiNGRkZGRkYiPjwvcGF0aD4gICAgICAgIDxwb2x5Z29uIGlkPSJTaGFwZSIgc3Ryb2tlPSIjRkZGRkZGIiBwb2ludHM9IjE0LjA1IDYuNDM3NSA2LjggMTIuMTg3NSAxIDEyLjE4NzUgMSAyMC44MTI1IDYuOCAyMC44MTI1IDE0LjA1IDI2LjU2MjUiPjwvcG9seWdvbj4gICAgPC9nPjwvc3ZnPg==);
  }
}
@media screen and (max-height: 764px) and (min-width: 1160px)  {
  .church-inner__bell {
    top: calc(100vh - 64px);
  }
  
}
.church-inner__bell--hidden{
  opacity: 0;
  visibility: hidden;
}
.church-inner__bell--muted::after{
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJBcnRib2FyZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxwYXRoIGQ9Ik0zMSwxMS43MTQyODU3IEwyMi40Mjg1NzE0LDIwLjI4NTcxNDMiIGlkPSJTaGFwZSIgc3Ryb2tlPSIjRkZGRkZGIj48L3BhdGg+ICAgICAgICA8cGF0aCBkPSJNMjIuNDI4NTcxNCwxMS43MTQyODU3IEwzMSwyMC4yODU3MTQzIiBpZD0iU2hhcGUiIHN0cm9rZT0iI0ZGRkZGRiI+PC9wYXRoPiAgICAgICAgPHBvbHlnb24gaWQ9IlNoYXBlIiBzdHJva2U9IiNGRkZGRkYiIHBvaW50cz0iMTQuMDUgNi40Mzc1IDYuOCAxMi4xODc1IDEgMTIuMTg3NSAxIDIwLjgxMjUgNi44IDIwLjgxMjUgMTQuMDUgMjYuNTYyNSI+PC9wb2x5Z29uPiAgICA8L2c+PC9zdmc+);
}

.church-inner__poster{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}
.church-inner__poster--hidden{
  opacity: 0;
  visibility: hidden;
}

@media (max-width: $screen-md) {
  .church-inner {
    .main-controls__arrow.prev {
      left: 20px;
    }
    .main-controls__arrow.next {
      right: 20px;
    }
    .church-inner__bell{
      top: calc(90% - 19px);
    }
    .slider__dots{
      display: none!important;
    }
  }
}