.main--sitemap {
  height: 100%;
}

.sitemap {
  width: 100%;
  height: 100%;
  background: url('../img/church/bg.jpg') center no-repeat / cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  h1 {
    margin-bottom: 50px;
    font-size: 48px;
    text-transform: uppercase;
  }
  ul {
    li {
      a {
        padding: 10px;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
        transition: all .2s;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    li+li {
      margin-top: 30px;
    }
  }
}