// Настройки

*, *:after, *:before {
  vertical-align: baseline;
  font-size: 100%;
  border: 0 none;
  outline: 0 none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body {
	width: 100%;
	height: 100%;
	font-family: 'Ubuntu', Arial, sans-serif;
	color: $main-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

body {
  overflow-x: hidden;
}

ul,li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: black;
}

img {
  max-width: 100%;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}