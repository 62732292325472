.back {
  width: 190px;
  height: 50px;
  @include flexCenter;
  background: url('../img/general/bg-back-link.png') center no-repeat;
  background-size: contain;
  &:hover {
    .back__icon {
      margin-right: 15px;
    }
  }
}

.back__icon {
  margin-right: 10px;
  transition: all .2s;
  svg {
    width: 15px;
    use {
      stroke: $link-color;
    }
  }
}

.back__text {
  color: $link-color;
  font-size: 14px;
}