.about__top {
  min-height: 600px;
  height: 600px;
  @media (max-width: $screen-lg) {
    min-height: 400px;
    height: 400px;
  }
  @media (max-width: $screen-md) {
    min-height: auto;
    height: auto;
    padding: 40px 0;
  }
  @media (max-width: $screen-sm) {
    min-height: 600px;
    height: 600px;
    padding: 0;
  }
}

.about__slider {
  .slick-list {
    padding-bottom: 25px;
    box-sizing: content-box;
  }
  .slider__item {
    max-height: 720px;
  }
  .slider__item-wrap {
    padding-bottom: 20px;
    position: relative;
  }
}


.about__company {
  .col {
    &:first-child {
      max-width: 880px;
    }
    &:last-child {
      max-width: 200px;
    }
  }
}

.about__title {
  @include playfair;
  font-size: 36px;
  line-height: 32px;
}

.about__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &--organization {
    .about__item {
      &:nth-child(3) {
        max-width: 280px;
      }
    }
  }
  &--partners {
    .about__item {
      &:last-child {
        margin: 0 auto 0 25px;
      }
    }
  }
  @media (max-width: $screen-md) {
    &--partners {
      .about__item {
        &:last-child {
          margin: 0 auto 0 0;
        }
      }
    }
  }
}

.about__item {
  max-width: 250px;
  @media (max-width: $screen-md) {
    margin-bottom: 40px;
  }
}

.about__img {
  margin-bottom: 20px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about__text {
  color: $main-color-07;
  font-size: 14px;
  line-height: 24px;
}

.about__team {
  background: url('../img/about/bg-team.jpg') center no-repeat;
  background-size: cover;
  padding: 80px 0;
  .col {
    &:first-child {
      max-width: 830px;
    }
    &:last-child {
      max-width: 200px;
    }
  }
}

.about__row {
  display: flex;
  justify-content: space-between;
  @media (max-width: $screen-md) {
    display: block;
  }
}

.about__col {
  width: 100%;
  max-width: 180px;
  &:last-child {
    max-width: 230px;
  }
  @media (max-width: $screen-md) {
    max-width: 100%;
    &:last-child {
      max-width: 100%;
    }
    &+& {
      margin-top: 30px;
    }
  }
}

.about__block {
  &+& {
    margin-top: 30px;
  }
}

.about__post {
  color: $main-color-07;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.about__name {
  @include playfair;
  font-size: 18px;
  line-height: 26px;
  &+& {
    margin-top: 30px;
  }
}