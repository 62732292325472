.nav {
  display: flex;
  @media(max-width: $screen-md) {
    flex-direction: column;
  }
}

.nav__link {
  @include playfair;
  display: block;
  color: $nav-color;
  text-transform: uppercase;
  letter-spacing: 1.49px;
  padding: 32px 0;
  position: relative;
  @extend .trs;
  &+& {
    margin-left: 40px;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: $link-color;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    @extend .trs;
  }
  &:hover {
    color: $link-color;
  }
  &.active {
    color: $link-color;
    &:before {
      opacity: 1;
    }
  }
  @media(max-width: $screen-md) {
    align-self: flex-start;
    font-size: 24px;
    padding-left: 20px;
    &+& {
      margin-left: 0px;
    }
    &:before {
      height: 30px;
      width: 3px;
      top: 28px;
    }
  }
}