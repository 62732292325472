@mixin centerIt() {
	position: absolute;
	left:50%;
	top:50%;
	transform:translate(-50%, -50%);
}

@mixin centerItHorizintal() {
	position: absolute;
	left:50%;
	transform:translateX(-50%);
}

@mixin centerItVertital() {
	position: absolute;
	top:50%;
	transform:translateY(-50%);
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin absoluteZero {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@mixin playfair {
	font-family: 'Playfair', Arial, sans-serif;
	font-weight: 900;
}

@mixin playfair-regular {
	font-family: 'Playfair', Arial, sans-serif;
	font-weight: 400;
}

@mixin ubuntu-medium {
	font-family: 'Ubuntu', Arial, sans-serif;
	font-weight: 500;
}

@mixin ubuntu-light {
	font-family: 'Ubuntu', Arial, sans-serif;
	font-weight: 300;
}
