.slider {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

.slider__item {
  position: relative;
  max-height: 600px;
  overflow: hidden;
  img {
    width: 100%;
    max-width: 100%;
  }
  @media(max-width: $screen-md) {
    img {
      width: auto;
      max-width: none;
      transform: translateX(-40%);
      height: 600px;
    }
  }
}

.slider__control {
  @include centerItVertital;
  @extend .trs;
  cursor: pointer;
  opacity: 0.6;
  &.prev {
    left: 20px;
    padding-right: 5px;
    padding-left: 13px;
  }
  &.next {
    right: 20px;
    padding-left: 5px;
    padding-right: 13px;
  }
  &:hover {
    opacity: 1;
    &.prev {
      left: 20px;
      padding-left: 0;
      padding-right: 18px;
    }
    &.next {
      right: 20px;
      padding-right: 0;
      padding-left: 18px;
    }
  }
}

.slider__dots {
  @include centerItHorizintal;
  bottom: 30px;
  display: flex;
  li {
    cursor: pointer;
    &:hover {
      span {
        background-color: $accent-color;
      }
    }
    &.slick-active {
      span {
        background-color: $accent-color;
      }
    }
    span {
      display: block;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #ffffff;
      @extend .trs;
    }
  }
  li+li {
    margin-left: 20px;
  }
  @media(max-width: $screen-md) {
    bottom: 10px;
    flex-wrap: wrap;
    max-width: 100%;
    width: 95%;
    justify-content: center;
    li {
      margin: 0 5px 5px;
    }
    li + li {
      margin-left: 5px;
    }
  }
}

.slider__info {
  position: absolute;
  cursor: pointer;
  svg {
    @extend .trs;
    transform-origin: center;
  }
  &:hover {
    svg {
      transform: scale(1.1)
    }
  }
}

.slider__more {
  position: absolute;
  z-index: 1;
  text-align: center;
  padding: 90px 70px 0 40px;
  max-width: 450px;
  top: -80px;
  right: -100%;
  @extend .trs-long;
  visibility: hidden;
  &.active {
    right: 0;
    visibility: visible;
  }
  @media(max-width: $screen-md) {
    padding: 90px 40px 0;
  }
}

.slider__more-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.slider__close {
  margin-bottom: 90px;
  cursor: pointer;
  svg {
    @extend .trs;
    transform-origin: center;
  }
  &:hover {
    svg {
      transform: scale(1.1)
    }
  }
}

.slider__title {
  @include playfair;
  color: $main-color;
  font-size: 32px;
  line-height: 36px;
  display: block;
  margin-bottom: 40px;
}

.slider__text {
  color: $main-color-07;
  font-size: 16px;
  line-height: 29px;
}

.slider__name {
  @include playfair;
  @include centerItHorizintal;
  height: auto;
  bottom: 0;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 40px;
  background: $accent-color;
  border-radius: 20px;
  @media (max-width: $screen-lg) {
    font-size: 14px;
    padding: 10px 30px;
  }
}


@media (max-width: $screen-md) {
  .slider__item{
    max-height: 100vh;
    text-align: center;
    .slider__img{
      display: inline-block;
      width: 100%;
      height: auto;
    }
    img{
      transform: translateX(0);
    }
  }
  .slider__name{
    position: relative;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
}