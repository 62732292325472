.page--error {
  overflow: hidden;
}


.main--error {
  width: 100%;
  height: 100%;

  .preloader__info {
    z-index: 2;
  }

  .preloader__text {
    max-width: 100%;
    a {
      color: $link-color;
      @extend .trs;
      &:hover {
        color: $hover-link-color;
      }
    }
  }

  .preloader__clouds {
    z-index: -1;
  }
}

.error {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: url('../img/error/bg.jpg') center no-repeat / cover;
  position: relative;
  z-index: 2;
}

.error__subtitle {
  display: block;
  margin-bottom: 100px;
}