.main-controls__arrow {
  display: flex;
  align-items: center;
  position: absolute;
  @include centerItVertital;
  @extend .trs;
  &.prev {
    left: 30px;
  }
  &.next {
    flex-direction: row-reverse;
    right: 30px;
    .main-controls__icon {
      margin-right: 0;
      margin-left: 20px;
    }
  }
  &:hover {
    &.prev {
      left: 15px;
      .main-controls__icon {
        margin-right: 35px;
      }
    }
    &.next {
      right: 15px;
      .main-controls__icon {
        margin-left: 35px;
      }
    }
  }
  @media (max-width: $screen-lg) {
    top: 90%;
    &.prev {
      left: 35%;
    }
    &.next {
      right: 35%;
    }
    &:hover {
      &.prev {
        left: 35%;
      }
      &.next {
        right: 35%;
      }
    }
  }
}

.main-controls__icon {
  margin-right: 20px;
  @extend .trs;
}

.main-controls__text {
  @include playfair;
  max-width: 170px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  @media (max-width: $screen-lg) {
    display: none;
  }
}