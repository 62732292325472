// Общие стили

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 130px 0 230px;
  margin: 0 auto;
  @media (max-width: 1440px) {
    max-width: 1080px;
    padding: 0;
  }
  @media (max-width: $screen-lg) {
    padding: 0 10px;
  }
}

.main--index {
  width: 100%;
  height: 100%;
  background: url('../img/church/bg.jpg') center no-repeat;
  background-size: cover;
  @media(max-width: 1600px) {
    background: none;
  }
}

.page--index {
  overflow: hidden;
  min-height: 750px;
  @media(max-width: $screen-md) {
    overflow: visible;
  }
}

.trs {
  transition: all .2s;
}

.trs-long {
  transition: all .5s;
}

.row {
  display: flex;
  justify-content: space-between;
  @media(max-width: $screen-md) {
    display: block;
  }
}

.col {
  width: 100%;
  &:first-child {
    max-width: 780px;
  }
  &:nth-child(2) {
    max-width: 220px;
  }
  @media(max-width: $screen-md) {
    &:first-child {
      max-width: 100%;
      margin-bottom: 40px;
    }
    &:nth-child(2) {
      max-width: 100%;
      padding: 20px 0 30px;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
    }
  }
}

.hidden-lg {
  display: none;
  @media(max-width: $screen-md) {
    display: block;
  }
}

.hidden-md {
  @media(max-width: $screen-md) {
    display: none;
  }
}

.overflowhidden {
  overflow: hidden;
}