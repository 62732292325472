.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 3;
  &.anim {
    .preloader__title,
    .preloader__subtitle,
    .preloader__text,
    .preloader__list {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  @media(max-width: $screen-md) {
    display: none;
  }
}

.preloader__title {
  @include playfair;
  color: $accent-color;
  font-size: 64px;
  margin-bottom: 15px;
  @extend .trs;
  transition-delay: .1s;
}

.preloader__subtitle {
  font-size: 24px;
  @extend .trs;
  transition-delay: .2s;
}

.preloader__info {
  @include centerItHorizintal;
  bottom: 57px;
}

.preloader__text {
  max-width: 460px;
  color: $light-main-color;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto 30px;
  @extend .trs;
  transition-delay: .3s;
}

.preloader__list {
  display: flex;
  justify-content: center;
  align-items: center;
  @extend .trs;
  transition-delay: .4s;
}

.preloader__item {
  &+& {
    margin-left: 40px;
  }
}

.preloader__clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  &.anim-clouds {
    .preloader__cloud {
      animation: cloudsAnim 5s infinite;
    }
  }
  &.anim {
    .preloader__cloud {
      &--1 {
        animation: translateAnim 1s forwards;
      }
      &--2,
      &--5 {
        animation: translateAnim-2 1s forwards;
      }
      &--3,
      &--6,
      &--7 {
        animation: translateAnim-3 1s forwards;
      }
      &--4 {
        animation: translateAnim-4 1s forwards;
      }
    }
  }
}

.preloader__cloud {
  position: absolute;
  z-index: -1;
  transition: all 1s;
  &--1 {
    top: 0;
    left: 0;
  }
  &--2 {
    bottom: 0;
    left: 0;
  }
  &--3 {
    top: 0;
    right: 0;
  }
  &--4 {
    bottom: 0;
    right: 0;
  }
  &--5 {
    bottom: 0;
    left: 0;
  }
  &--6 {
    right: 0;
    top: 153px;
  }
  &--7 {
    right: 0;
    top: 70px;
  }
  @media(max-width: $screen-md) {
    &--1,
    &--7 {
      top: 45%;
    }
    &--5 {
      bottom: 45%;
    }
  }
}

@keyframes cloudsAnim {
  from {
    transform: translateX(30px)
  }
  50% {
    transform: translateX(-30px)
  }
  to {
    transform: translateX(30px)
  }
}

@keyframes translateAnim {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(-100%, -100%)
  }
}

@keyframes translateAnim-2 {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(-100%, 100%)
  }
}

@keyframes translateAnim-3 {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(100%, -100%)
  }
}

@keyframes translateAnim-4 {
  from {
    transform: translate(0, 0)
  }
  to {
    transform: translate(100%, 100%)
  }
}