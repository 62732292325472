.show-map {
  width: 220px;
  height: 300px;
  @include flexCenter;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
  @extend .trs;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    opacity: 1;
  }
  @media(max-width: $screen-md) {
    margin: 0 auto 40px;
  }
}

.show-map__icon {
  margin-bottom: 20px;
}

.show-map__text {
  @include playfair;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
}