.header {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 30px;
  z-index: 5;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
  @extend .trs-long;
  &.active {
    top: 0;
  }
  &--inner {
    top: 0;
    background: transparent;
    position: absolute;
    .header__title,
    .header__subtitle,
    .nav__link,
    .header__text {
      color: white;
    }
    .header__subtitle {
      opacity: 1;
    }
    .nav__link {
      &:before {
        background: white;
      }
      &:hover {
        color: $dark-gray-color;
      }
      &.active {
        color: white;
      }
    }
    .header__version {
      &:hover {
        .header__icon {
          svg {
            stroke: $dark-gray-color;
          }
        }
        .header__text {
          color: $dark-gray-color;
        }
      }
    }
    .header__icon {
      svg {
        stroke: white
      }
    }
  }
  @media(max-width: $screen-lg) {
    padding: 10px;
    &--inner {
      top: 0;
      position: relative;
      background: #fff;
      .header__title {
        color: $accent-color;
      }
      .header__subtitle {
        color: $light-gray-color;
      }
      .nav__link,
      .header__text {
        color: $nav-color;
      }
      .nav__link {
        &:before {
          background-color: $link-color;
        }
        &:hover {
          color: $link-color;
        }
        &.active {
          color: $link-color;
        }
      }
      .header__version {
        &:hover {
          .header__icon {
            svg {
              stroke: $link-color;
            }
          }
          .header__text {
            color: $link-color;
          }
        }
      }
      .header__icon {
        svg {
          stroke: $nav-color;
        }
      }
    }
  }
}

.header--hidden{
  opacity: 0;
  visibility: hidden;
}

.header__title {
  @include playfair;
  color: $accent-color;
  font-size: 20px;
  margin-bottom: 5px;
}

.header__subtitle {
  opacity: 0.4;
  color: $light-gray-color;
  font-size: 14px;
}

.header__nav {
  @media(max-width: $screen-lg) {
    display: none;
  }
}

.header__version {
  display: flex;
  align-items: center;
  padding: 5px;
  &:hover {
    .header__icon {
      svg {
        stroke: $link-color;
      }
    }
    .header__text {
      color: $link-color;
    }
  }
  @media(max-width: $screen-lg) {
    display: none;
  }
}

.header__icon {
  margin-right: 10px;
  display: flex;
  svg {
    stroke: $nav-color;
    @extend .trs;
  }
}

.header__text {
  color: $nav-color;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  @extend .trs;
  &.hide {
    display: none;
  }
  @media(max-width: $screen-lg) {
    color: #808485;
  }
}

.header__burger {
  $width: 26px;
  $height: 3px;
  $translate: 6px;
  $bg: $link-color;

  cursor: pointer;
  display: none;
  flex-direction: column;
  &::before,&::after, span {
    content: "";
    display: block;
    width: $width;
    height: $height;
    background: $bg;
    transition: all .2s;
  }
  &::before {
    margin-bottom: $translate;
  }
  &::after {
    margin-top: $translate;
  }
  &.active {
    span {
      opacity: 0;
    }
    &::before {
      margin-bottom: 0;
      transform: translateY(3px) rotate(45deg);
    }
    &::after {
      margin-top: 0;
      transform: translateY(-3px)  rotate(-45deg);
    }
  }
  @media(max-width: $screen-lg) {
    display: flex;
  }
}

.header__type {
  display: none;
  align-items: center;
  padding: 0 30px;
  margin-left: auto;
  position: relative;
  &:before,&:after {
    content: '';
    width: 1px;
    height: 86px;
    top: -25px;
    position: absolute;
    background: #000;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  &.active {
    display: flex;
  }
  &-text {
    display: block;
    margin-right: 10px;
  }
}

.header__type-item {
  padding: 5px;
  border: 1px solid #000;
  cursor: pointer;
  &+& {
    margin-left: 5px;
  }
  &:nth-child(2) {
    font-size: 16px !important;
  }
  &:nth-child(3) {
    font-size: 18px !important;
  }
  &:nth-child(4) {
    font-size: 20px !important;
  }
}

.header__mobile {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 30px 0 40px;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 60px);
}

.header__mobile-version {
  .header__version {
    display: flex;
    margin-bottom: 30px;
  }
}

.header__mobile-info {
  border-top: 1px solid #efefef;
  padding: 40px 10px 0;
}

.header__mobile-text {
  color: $light-main-color;
  line-height: 24px;
  margin-bottom: 40px;
}

.header__mobile-logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.header__mobile-logo {
  margin: 0 20px 30px;
}

.header__type--mm{
  display: none;
}
@media (max-width: $screen-md) {
  .header__type--top{
    display: none!important;
  }
  .header__type--mm.active{
    display: flex;
    margin-bottom: 20px;
    &:before{
      display: none;
    }
  }
}