.interactiv-bsyzgan {
  position: relative;
}

.interactiv-bsyzgan__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.interactiv-bsyzgan__item {
  width: 100%;
  max-width: 30%;
  cursor: pointer;
}

.interactiv-bsyzgan__more {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.5);
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 5;
  &.active {
    display: flex;
  }
}

.interactiv-bsyzgan__block {
  padding: 20px;
}

.interactiv-bsyzgan__more-img {
  display: none;
  max-width: 80%;
  margin: 0 auto;
  &.active {
    display: block;
  }
}