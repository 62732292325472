.main--church-bsyzgan {
  .church-inner__interactiv {
    .row {
      @media (max-width: $screen-md) {
        position: relative;
      }
    }
    .col {
      position: relative;
      @media (max-width: $screen-lg) {
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
      @media (max-width: $screen-md) {
        position: static;
        &:nth-child(2) {
          margin-left: 0;
        }
      }
    }
    .church-inner__descr {
      @media (max-width: $screen-lg) {
        margin-left: 40px;
      }
      .hidden-lg {
        display: inline;
      }
    }
  }
}

.bsyzgan-interactiv {
  position: relative;
  @media (max-width: $screen-lg) {
    max-width: 490px;
    margin: 0 auto;
  }
  @media (max-width: $screen-md) {
    max-width: 440px;
    margin: 0 auto;
  }
  @media (max-width: $screen-sm) {
    max-width: 300px;
    margin: 0 auto;
  }
}

.bsyzgan-interactiv__more {
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: calc(50% - 50vw);
    top: calc(50% - 50vh);
    display: none;
    background: rgba(#000, 0.5);
    z-index: 0;
  }
  @media (max-width: $screen-md) {
    &.active {
      &:before {
        display: block;
      }
    }
  }
}

.bsyzgan-interactiv__more-block {
  @include centerItVertital;
  opacity: 0;
  visibility: hidden;
  z-index: -10;
  color: $main-color-07;
  font-size: 14px;
  line-height: 24px;
  @extend .trs;
  span {
    @include playfair;
    font-size: 24px;
  }
  @media (max-width: $screen-lg) {
    font-size: 12px;
    span {
      font-size: 18px;
    }
  }
  @media (max-width: $screen-md) {
    font-size: 14px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 460px;
    padding: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
      font-size: 22px;
    }
  }
  @media (max-width: $screen-sm) {
    max-width: 300px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
}


.bsyzgan-interactiv__words {
  position: absolute;
  cursor: pointer;
  transition: all .2s;
  &:hover {
    transform: scale(1.1);
  }
  &--1 {
    top: 55px;
    left: 41%;
    max-width: 150px;
  }
  &--2 {
    top: 110px;
    left: 41%;
    max-width: 150px;
  }
  &--3 {
    top: 200px;
    left: 40px;
    max-width: 150px;
  }
  &--4 {
    top: 195px;
    left: 212px;
    max-width: 75px;
  }
  &--5 {
    top: 195px;
    left: 44%;
    max-width: 90px;
  }
  &--6 {
    top: 265px;
    right: 40px;
    max-width: 150px;
  }
  &--7 {
    top: 350px;
    left: 125px;
    max-width: 120px;
  }
  &--8 {
    top: 350px;
    right: 150px;
    max-width: 90px;
  }
  &--9 {
    top: 400px;
    left: 120px;
    max-width: 150px;
  }
  &--10 {
    top: 400px;
    left: 41%;
    max-width: 150px;
  }
  &--11 {
    top: 420px;
    right: 180px;
    max-width: 60px;
  }
  &--12 {
    top: 438px;
    left: 45%;
    max-width: 65px;
  }
  &--13 {
    top: 500px;
    left: 45%;
    max-width: 80px;
  }
  &--14 {
    top: 590px;
    left: 36%;
    max-width: 220px;
  }
  &--15 {
    top: 650px;
    left: 155px;
    max-width: 90px;
  }
  &--16 {
    top: 650px;
    right: 155px;
    max-width: 90px;
  }
  &--17 {
    top: 750px;
    left: 43%;
    max-width: 100px;
  }
  &--18 {
    top: 865px;
    left: 270px;
    max-width: 90px;
  }
  &--19 {
    top: 865px;
    right: 275px;
    max-width: 90px;
  }
  &--20 {
    top: 985px;
    left: 44%;
    max-width: 90px;
  }
  &--21 {
    top: 205px;
    right: 40px;
    max-width: 150px;
  }
  @media (max-width: $screen-lg) {
    &--1 {
      top: 40px;
      left: 40%;
      max-width: 100px;
    }
    &--2 {
      top: 74px;
      left: 40%;
      max-width: 100px;
    }
    &--3 {
      top: 128px;
      left: 23px;
      max-width: 100px;
    }
    &--4 {
      top: 125px;
      left: 140px;
      max-width: 50px;
    }
    &--5 {
      top: 125px;
      left: 43%;
      max-width: 70px;
    }
    &--6 {
      top: 170px;
      right: 35px;
      max-width: 90px;
    }
    &--7 {
      top: 220px;
      left: 85px;
      max-width: 80px;
    }
    &--8 {
      top: 220px;
      right: 90px;
      max-width: 60px;
    }
    &--9 {
      top: 253px;
      left: 85px;
      max-width: 90px;
    }
    &--10 {
      top: 250px;
      left: 40%;
      max-width: 95px;
    }
    &--11 {
      top: 260px;
      right: 115px;
      max-width: 40px;
    }
    &--12 {
      top: 270px;
      left: 45%;
      max-width: 45px;
    }
    &--13 {
      top: 318px;
      left: 45%;
      max-width: 50px;
    }
    &--14 {
      top: 377px;
      left: 38%;
      max-width: 127px;
    }
    &--15 {
      top: 408px;
      left: 95px;
      max-width: 60px;
    }
    &--16 {
      top: 408px;
      right: 95px;
      max-width: 60px;
    }
    &--17 {
      top: 475px;
      left: 43%;
      max-width: 70px;
    }
    &--18 {
      top: 543px;
      left: 170px;
      max-width: 65px;
    }
    &--19 {
      top: 543px;
      right: 173px;
      max-width: 65px;
    }
    &--20 {
      top: 617px;
      left: 44%;
      max-width: 55px;
    }
    &--21 {
      top: 135px;
      right: 33px;
      max-width: 90px;
    }
  }
  @media (max-width: $screen-md) {
    &--1 {
      top: 30px;
      left: 40%;
      max-width: 80px;
    }
    &--2 {
      top: 60px;
      left: 40%;
      max-width: 90px;
    }
    &--3 {
      top: 113px;
      left: 20px;
      max-width: 90px;
    }
    &--4 {
      top: 110px;
      left: 121px;
      max-width: 45px;
    }
    &--5 {
      top: 110px;
      left: 43%;
      max-width: 65px;
    }
    &--6 {
      top: 145px;
      right: 28px;
      max-width: 80px;
    }
    &--7 {
      top: 197px;
      left: 71px;
      max-width: 70px;
    }
    &--8 {
      top: 197px;
      right: 77px;
      max-width: 60px;
    }
    &--9 {
      top: 225px;
      left: 64px;
      max-width: 90px;
    }
    &--10 {
      top: 225px;
      left: 40%;
      max-width: 95px;
    }
    &--11 {
      top: 233px;
      right: 97px;
      max-width: 40px;
    }
    &--12 {
      top: 241px;
      left: 45%;
      max-width: 40px;
    }
    &--13 {
      top: 283px;
      left: 44%;
      max-width: 50px;
    }
    &--14 {
      top: 337px;
      left: 35%;
      max-width: 127px;
    }
    &--15 {
      top: 366px;
      left: 85px;
      max-width: 55px;
    }
    &--16 {
      top: 363px;
      right: 85px;
      max-width: 55px;
    }
    &--17 {
      top: 420px;
      left: 42%;
      max-width: 70px;
    }
    &--18 {
      top: 486px;
      left: 153px;
      max-width: 55px;
    }
    &--19 {
      top: 486px;
      right: 155px;
      max-width: 55px;
    }
    &--20 {
      top: 551px;
      left: 44%;
      max-width: 55px;
    }
    &--21 {
      top: 115px;
      right: 25px;
      max-width: 80px;
    }
  }
  @media (max-width: $screen-sm) {
    &--1 {
      top: 21px;
      left: 40%;
      max-width: 65px;
    }
    &--2 {
      top: 37px;
      left: 41%;
      max-width: 57px;
    }
    &--3 {
      top: 78px;
      left: 7px;
      max-width: 66px;
    }
    &--4 {
      top: 76px;
      left: 84px;
      max-width: 35px;
    }
    &--5 {
      top: 72px;
      left: 43%;
      max-width: 46px;
    }
    &--6 {
      top: 96px;
      right: 19px;
      max-width: 55px;
    }
    &--7 {
      top: 129px;
      left: 47px;
      max-width: 54px;
    }
    &--8 {
      top: 129px;
      right: 52px;
      max-width: 45px;
    }
    &--9 {
      top: 151px;
      left: 46px;
      max-width: 67px;
    }
    &--10 {
      top: 150px;
      left: 40%;
      max-width: 70px;
    }
    &--11 {
      top: 155px;
      right: 67px;
      max-width: 30px;
    }
    &--12 {
      top: 163px;
      left: 45%;
      max-width: 30px;
    }
    &--13 {
      top: 187px;
      left: 42%;
      max-width: 50px;
    }
    &--14 {
      top: 226px;
      left: 35%;
      max-width: 86px;
    }
    &--15 {
      top: 247px;
      left: 57px;
      max-width: 40px;
    }
    &--16 {
      top: 247px;
      right: 57px;
      max-width: 40px;
    }
    &--17 {
      top: 279px;
      left: 41%;
      max-width: 55px;
    }
    &--18 {
      top: 334px;
      left: 104px;
      max-width: 45px;
    }
    &--19 {
      top: 334px;
      right: 104px;
      max-width: 45px;
    }
    &--20 {
      top: 375px;
      left: 42%;
      max-width: 55px;
    }
    &--21 {
      top: 78px;
      right: 18px;
      max-width: 55px;
    }
  }
}