.subinfo__title {
  @include playfair;
  color: $nav-color;
  font-size: 24px;
  line-height: 32px;
  display: block;
  margin-bottom: 10px;
}

.subinfo__text {
  color: $main-color-07;
  font-size: 14px;
  line-height: 24px;
}