.text {
  @include playfair-regular;
  color: $nav-color;
  font-size: 16px;
  line-height: 28px;
  &+& {
    margin-top: 20px;
  }
  &--heading {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 40px;
  }
}



@media (max-width: $screen-md) {
  .text--heading{
    font-size: 18px;
    line-height: 28px;
  }
}