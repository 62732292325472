.church-nav {
  @include centerItVertital;
  right: -100%;
  z-index: 5;
  @extend .trs-long;
  &.active {
    right: 30px;
  }
  @media(max-width: $screen-md) {
    display: none;
  }
}

.church-nav__item {
  @include playfair;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  &+& {
    margin-top: 27px;
    &:after {
      display: block;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    @include centerItHorizintal;
    bottom: 100%;
    width: 2px;
    height: 30px;
    background: $accent-color;
    display: none;
  }
  &:hover {
    .church-nav__name {
      opacity: 1;
      visibility: visible;
    }
  }
  &.active {
    .church-nav__number:after {
      opacity: 1;
    }
    .church-nav__number {
      color: white
    }
  }
}

.church-nav__name {
  display: block;
  position: absolute;
  top: 15px;
  right: calc(100% + 15px);
  color: $hover-link-color;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  @extend .trs-long;
}

.church-nav__number {
  @include flexCenter;
  text-transform: uppercase;
  color: $accent-color;
  width: 46px;
  height: 56px;
  position: relative;
  z-index: 5;
  @extend .trs;
  &:before,&:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @extend .trs;
  }
  &:before {
    background: url('../img/church-nav/bg.png');
    z-index: -2;
  }
  &:after {
    background: url('../img/church-nav/bg-active.png');
    z-index: -1;
    opacity: 0;
  }
}