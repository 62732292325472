html {
  &.state-contrast-small {
    &.mod--1 {
      & * {
        font-size: 16px !important;
      }
      h1 {
        font-size: 48px !important;
      }
      .header__type-item {
        &:nth-child(2) {
          font-size: 16px !important;
        }
        &:nth-child(3) {
          font-size: 18px !important;
        }
        &:nth-child(4) {
          font-size: 20px !important;
        }
      }
    }
    &.mod--2 {
      & * {
        font-size: 18px !important;
      }
      h1 {
        font-size: 52px !important;
      }
      .header__type-item {
        &:nth-child(2) {
          font-size: 16px !important;
        }
        &:nth-child(3) {
          font-size: 18px !important;
        }
        &:nth-child(4) {
          font-size: 20px !important;
        }
      }
    }
    &.mod--3 {
      & * {
        font-size: 20px !important;
      }
      h1 {
        font-size: 54px !important;
      }
      .header__type-item {
        &:nth-child(2) {
          font-size: 16px !important;
        }
        &:nth-child(3) {
          font-size: 18px !important;
        }
        &:nth-child(4) {
          font-size: 20px !important;
        }
      }
    }
    & *:not([class^="ymaps"]) {
      font-family: sans-serif !important;
      color: #000 !important;
      font-size: 16px !important;
      line-height: 1.4em !important;
      background-image: none !important;
      background-color: #fff !important;
      &:before,&:after {
        background-image: none !important;
      }
    }
    .church__name {
      min-width: 320px;
    }
    h1 {
      font-size: 48px !important;
    }
    .header {
      border-bottom: 1px solid #000;
    }
    .header__nav {
      margin-left: auto;
    }
    .header--inner .header__icon svg {
      stroke: #000;
    }
    img,
    .header__mobile-logos,
    .back__icon,
    .church__line,
    .church-inner__descr-icon,
    .map-info__icon{
      display: none;
    }
    .header__burger {
      &:after,&:before,span {
        background: #000 !important;
      }
    }
    .map-info{
      padding: 50px;
      margin-top: 100px;
    }

    .church-inner__top--hidden {
      .church-inner__breadcrumbs,
      .church-inner__main-controls,
      .church-inner__more-history,
      .church-inner__title {
        opacity: 1;
        visibility: visible;
      }
    }
    .header--hidden{
      opacity: 1;
      visibility: visible;
    }
    .church-inner__bell{
      display: none;
    }
    .about__slider .slider__name{
      position: relative;
      text-align: center;
    }
    .glossary__char-item {
      &.active {
        font-weight: 700;
      }
      &:not(.noactive){
        border-bottom: 1px solid black;
      }
    }
  }
}