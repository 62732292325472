.map-info {
  position: relative;
  z-index: 5;
  text-align: center;
  padding: 100px 50px 0;
  max-width: 395px;
  @media (max-width: $screen-md) {
    margin: 0 auto;
    padding-top: 40px;
  }
}

.map-info__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.map-info__title {
  @include playfair;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 40px;
  @media (max-width: $screen-md) {
    font-size: 22px;
    line-height: 28px;
  }
}

.map-info__icon {
  margin-bottom: 40px;
  svg {
    use {
      fill: $accent-color;
    }
  }
}

.map-info__block {
  &+& {
    margin-top: 25px;
  }
}

.map-info__subtext {
  @include ubuntu-light;
  color: $map-info-subtext;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}

.map-info__text {
  color: $main-color-07;
  font-size: 16px;
  line-height: 24px;
  display: block;
  &+& {
    margin-top: 10px;
  }
}

.map-info__link {
  @extend .trs;
  &:hover {
    color: $main-color;
  }
}