.interactiv {
  @include flexCenter;
  color: #ffffff;
  flex-direction: column;
  height: 510px;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.interactiv__subtitle {
  @include ubuntu-medium;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 20px;
}

.interactiv__title {
  @include playfair;
  font-size: 36px;
  line-height: 42px;
  max-width: 480px;
  margin: 0 auto 50px;
}

.interactiv__link {
  @include playfair;
  background-color: $link-color;
  @include flexCenter;
  width: 270px;
  height: 50px;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  @extend .trs;
  border-radius: 20px;
  &:hover {
    background-color: $hover-link-color;
  }
  &.dgrad {
    width: 355px;
  }
}

.interactiv__youtube-wrap {
  display: flex;
  align-items: center;
}

.interactiv__youtube-icon {
  margin-right: 15px;
  overflow: hidden;
}

.interactiv__youtube {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  @extend .trs;
  z-index: 100;
  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.interactiv__youtube-inner {
  position: relative;
}

.interactiv__youtube-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: -50px;
  top: -50px;
  cursor: pointer;
  &:hover {
    &:before,&:after {
      background: rgba(255,255,255,1);
    }
  }
  &:before,&:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(255,255,255,.5);
    @include centerIt;
    @extend .trs;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}