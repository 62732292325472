.mb-40 {
  margin-bottom: 40px;
  @media(max-width: $screen-md) {
    margin-bottom: 20px;
  }
}

.mb-50 {
  margin-bottom: 50px;
  @media(max-width: $screen-md) {
    margin-bottom: 30px;
  }
}

.mb-60 {
  margin-bottom: 60px;
  @media(max-width: $screen-md) {
    margin-bottom: 40px;
  }
}

.mb-70 {
  margin-bottom: 70px;
  @media(max-width: $screen-md) {
    margin-bottom: 50px;
  }
}

.mb-80 {
  margin-bottom: 80px;
  @media(max-width: $screen-md) {
    margin-bottom: 60px;
  }
}

.mb-120 {
  margin-bottom: 120px;
  @media(max-width: $screen-md) {
    margin-bottom: 100px;
  }
}