.map {
  position: relative;
  height: 600px;
  overflow: hidden;
  @media (max-width: $screen-md) {
    height: auto;
    padding-top: 400px;
    padding-bottom: 150px;
    overflow: unset;
    min-height: 1000px;
  }
  @media (max-width: 340px) {
    padding-bottom: 50px;
  }
}

.map__info {
  position: relative;
  z-index: 2;
  max-width: 395px;
}

.map__map {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  bottom: 0;
  @media (max-width: $screen-md) {
    top: -50px;
    max-height: 600px;
    overflow: hidden;
  }
}