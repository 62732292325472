.footer {
  position: fixed;
  z-index: 5;
  width: 100%;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  bottom: 20px;
  &.hide {
    display: none;
  }
  &--inner {
    position: static;
    bottom: 0;
    padding: 32px 30px;
    display: block;
  }
}

.footer__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $screen-md) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    .nav__link {
      margin: 0 auto;
      &.active {
        color: $nav-color;
        &:before {
          display: none;
        }
      }
    }
  }
}

.footer__company {
  max-width: 340px;
  @media (max-width: $screen-md) {
    max-width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid #efefef;
  }
}

.footer__nav {
  @media (max-width: $screen-md) {
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px;
    width: 100%;
  }
}

.footer__dev {
  color: $gray-color;
  font-size: 14px;
  line-height: 22px;
}

.footer__link {
  color: $link-color;
  @extend .trs;
  &:hover {
    color: $hover-link-color;
  }
}