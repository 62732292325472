.surskoe-interactiv {
  position: relative;
}

.surskoe-interactiv__main {
  position: relative;
  @media(max-width: $screen-md) {
    max-width: 300px;
    margin: 0 auto;
  }
}

.surskoe-interactiv__item {
  position: absolute;
  z-index: 1;
  max-width: 126px;
  cursor: pointer;
  &.active {
    z-index: 2;
    .surskoe-interactiv__img {
      &:hover {
        .surskoe-interactiv__icon {
          opacity: 0;
        }
      }
    }
  }
  &--1 {
    top: 47px;
    left: 27px;
  }
  &--2 {
    top: 47px;
    left: 171px;
  }
  &--3 {
    top: 44px;
    left: 314px;
  }
  &--4 {
    top: 47px;
    left: 461px;
  }
  &--5 {
    max-width: 125px;
    top: 49px;
    left: 608px;
  }
  &--6 {
    left: 30px;
    top: 200px;
  }
  &--7 {
    top: 200px;
    left: 612px;
  }
  &--8 {
    left: 30px;
    top: 347px;
  }
  &--9 {
    left: 614px;
    top: 349px;
  }
  &--10 {
    left: 30px;
    top: 494px;
  }
  &--11 {
    left: 614px;
    top: 486px;
  }
  &--12 {
    left: 30px;
    top: 648px;
  }
  &--13 {
    left: 614px;
    top: 645px;
  }
  &--14 {
    left: 27px;
    top: 809px;
  }
  &--15 {
    left: 169px;
    top: 810px;
  }
  &--16 {
    left: 316px;
    top: 809px;
  }
  &--17 {
    left: 469px;
    top: 809px;
  }
  &--18 {
    left: 604px;
    top: 807px;
  }
  @media(max-width: $screen-md) {
    max-width: 50px;
    &--1 {
      top: 20px;
      left: 10px;
    }
    &--2 {
      top: 20px;
      left: 65px;
    }
    &--3 {
      top: 17px;
      left: 120px;
    }
    &--4 {
      top: 17px;
      left: 177px;
    }
    &--5 {
      max-width: 50px;
      top: 20px;
      left: 232px;
    }
    &--6 {
      left: 10px;
      top: 80px;
    }
    &--7 {
      top: 78px;
      left: 235px;
    }
    &--8 {
      left: 10px;
      top: 135px;
    }
    &--9 {
      left: 234px;
      top: 135px;
    }
    &--10 {
      left: 10px;
      top: 194px;
    }
    &--11 {
      left: 235px;
      top: 193px;
      max-width: 43px;
    }
    &--12 {
      left: 10px;
      top: 248px;
    }
    &--13 {
      left: 237px;
      top: 249px;
    }
    &--14 {
      left: 7px;
      top: 315px;
    }
    &--15 {
      left: 64px;
      top: 312px;
    }
    &--16 {
      left: 122px;
      top: 310px;
    }
    &--17 {
      left: 179px;
      top: 310px;
    }
    &--18 {
      left: 231px;
      top: 312px;
    }
  }
}

.surskoe-interactiv__img {
  position: relative;
  @extend .trs;
  img {
    max-width: 100%;
    width: 100%;
  }
  &:hover {
    box-shadow: 0px 0px 80px -40px rgba(0,0,0,0.75);
    .surskoe-interactiv__icon {
      opacity: 1;
    }
  }
}

.surskoe-interactiv__icon {
  @include centerIt;
  opacity: 0;
  @extend .trs;
  overflow: hidden;
}

.surskoe-interactiv__more {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -35px;
  left: -35px;
  z-index: -1;
  @extend .trs;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  @media(max-width: $screen-md) {
    top: -24px;
    left: -29px;
  }
}

.surskoe-interactiv__bg {
  img {
    max-width: 195px;
  }
  @media(max-width: $screen-md) {
    img {
      max-width: 118px;
    }
  }
}

.surskoe-interactiv__close {
  @include centerItHorizintal;
  top: -10px;
  cursor: pointer;
}

.surskoe-interactiv__title {
  @include centerItHorizintal;
  @include playfair;
  color: $nav-color;
  font-size: 12px;
  line-height: 16px;
  top: 187px;
  text-align: center;
  max-width: 150px;
  width: 100%;
  @media(max-width: $screen-md) {
    top: 100px;
    font-size: 10px;
    line-height: 14px;
  }
}