.glossary__top {
  min-height: 400px;
  height: 400px;
  @media (max-width: $screen-md) {
    height: auto;
    min-height: auto;
    padding: 40px 0;
    background: url('../img/glossary/bg.jpg') center no-repeat / cover;
    .church-inner__top-video {
      display: none;
    }
  }
}

.glossary__content {
  max-width: 1440px;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 430px 0 230px;
  @media (max-width: 1440px) {
    max-width: 1080px;
    padding: 0;
  }
  @media (max-width: $screen-lg) {
    padding: 0 10px;
  }
}

.glossary__char {
  margin-bottom: -35px;
}

.glossary__char-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px -15px;
  // @extend .trs;
  &.fixed {
    position: fixed;
    width: 100vw;
    top: 0px;
    left: calc(50% - 50vw);
    box-shadow: 0 10px 20px #efefef;
    background-color: #ffffff;
    margin: 0;
    padding: 10px;
    z-index: 3;
    justify-content: center;
    .glossary__char-item {
      margin-bottom: 0;
    }
  }
}

.glossary__char-item {
  @include flexCenter;
  @include ubuntu-light;
  width: 34px;
  height: 40px;
  margin: 0 5px;
  background: url('../img/glossary/bg-char.png') center no-repeat;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  @extend .trs;
  &:before,&:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    @extend .trs;
  }
  &:before {
    background: url('../img/glossary/bg-nochar.png') center no-repeat;
  }
  &:after {
    background: url('../img/glossary/bg-activechar.png') center no-repeat;
  }
  &:hover {
    color: #fff;
    &:after {
      opacity: 1;
    }
  }
  &.all {
    font-size: 14px;
  }
  &.noactive {
    cursor: default;
    &:before {
      opacity: 1;
    }
    &:hover {
      color: $main-color;
      &:after {
        opacity: 0;
      }
    }
  }
  &.active {
    cursor: default;
    color: #fff;
    &:after {
      opacity: 1;
    }
  }
  @media (max-width: $screen-sm) {
    /*width: 17px;
    height: 20px;*/
    background-size: cover;
    font-size: 12px;
    line-height: 18px;
    margin: 0 3px 3px;
    &.all {
      font-size: 12px;
    }
    &:before,&:after {
      background-size: cover;
    }
  }
}

.glossary__item {
  position: relative;
  padding-top: 30px;
  border-top: 1px solid $map-info-subtext;
  margin-top: 95px;
}

.glossary__item-title {
  color: $accent-color;
  @include playfair;
  font-size: 64px;
  line-height: 64px;
  position: absolute;
  top: -35px;
  left: -65px;
  @media (max-width: $screen-md) {
    left: 0;
    background-color: #fff;
  }
}

.gloosary__block {
  &+& {
    margin-top: 30px;
  }
}

.glossary__name {
  @include playfair;
  font-size: 18px;
  line-height: 26px;
  display: block;
  margin-bottom: 10px;
}

.glossary__text {
  color: $nav-color;
  @include playfair-regular;
  font-size: 16px;
  line-height: 28px;
}